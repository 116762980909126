import { B as BUNKER_STATUS_TEXTS, a as BUNKER_STATUS_COLORS } from "./bunker.status.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import { OPEN_PERMISSION, TRANSFER_PERMISSION, HOLD_PERMISSION, WRITEOFF_PERMISSION, HISTORY_PERMISSION, GROWERS_BUNKERPROCESSING } from "@kraftheinz/common";
var render$9 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_vm.recordItem && _vm.visibleModal ? _c("a-modal", {
          attrs: {
            "destroy-on-close": "",
            "title": "Writeoff Bunker",
            "visible": _vm.visibleModal,
            "centered": ""
          },
          on: {
            "ok": _vm.submitModal,
            "cancel": _vm.cancel
          },
          scopedSlots: _vm._u([{
            key: "footer",
            fn: function() {
              return [_c("a-button", {
                on: {
                  "click": _vm.cancel
                }
              }, [_vm._v("Cancel")]), _c("a-button", {
                attrs: {
                  "type": "primary"
                },
                on: {
                  "click": function($event) {
                    return handleSubmit(_vm.submitModal);
                  }
                }
              }, [_vm._v("OK")])];
            },
            proxy: true
          }], null, true)
        }, [_c("h4", {
          staticClass: "h4 mb-4"
        }, [_vm._v(_vm._s(_vm.recordItem.description ? _vm.recordItem.description : "BUNKER"))]), _c("a-row", {
          attrs: {
            "gutter": [24]
          }
        }, [_c("a-col", {
          attrs: {
            "xs": 24
          }
        }, [_c("div", {
          staticClass: "gross-weight"
        }, [_c("text-input", {
          attrs: {
            "form-item": "",
            "label": "Gross Weight",
            "suffix": "KG",
            "rules": "required"
          },
          on: {
            "change": _vm.onWeightObsChange
          }
        })], 1)]), _c("a-col", {
          attrs: {
            "xs": 12
          }
        }, [_c("select-input", {
          attrs: {
            "form-item": "",
            "label": "Truck",
            "data-source": _vm.bunkerTrucks,
            "source": "id",
            "source-label": "description",
            "rules": "required"
          },
          on: {
            "change": _vm.onTrucksChange
          }
        })], 1), _c("a-col", {
          attrs: {
            "xs": 12
          }
        }, [_c("label", [_vm._v("Truck Weight")]), _c("a-input", {
          attrs: {
            "disabled": true,
            "suffix": "KG",
            "span": 24
          },
          model: {
            value: _vm.tareValue,
            callback: function($$v) {
              _vm.tareValue = $$v;
            },
            expression: "tareValue"
          }
        })], 1), _c("a-col", {
          attrs: {
            "xs": 24
          }
        }, [_c("text-area", {
          attrs: {
            "form-item": "",
            "label": "Writeoff Reason",
            "max-length": 250,
            "value": _vm.writeoffReason,
            "rules": "required"
          },
          on: {
            "change": function($event) {
              return _vm.storeValue($event, "reason");
            }
          }
        })], 1)], 1)], 1) : _vm._e()];
      }
    }])
  });
};
var staticRenderFns$9 = [];
var WriteoffBunkerProcessing_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$9 = {
  name: "WriteoffBunkerProcessing",
  inject: ["apiUrl", "resourceProps"],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    record: {
      type: Object,
      default: null
    }
  },
  data() {
    const [writeoffBunkerProps, bunkerTrucksProps] = this.resourceProps;
    return {
      writeoffBunkerProps,
      bunkerTrucksProps,
      customUrl: `${this.apiUrl}/grower`,
      tareValue: 0,
      weightObserved: 0,
      writeoffReason: "",
      visibleModal: false,
      recordItem: null
    };
  },
  computed: {
    bunkerTrucks() {
      return this.bunkerTrucksProps.crud.getList();
    }
  },
  watch: {
    tareValue(newVal) {
      this.tareValue = newVal;
    },
    visible(newVal) {
      this.visibleModal = newVal;
    },
    record(newVal) {
      this.recordItem = newVal;
    }
  },
  async mounted() {
    this.bunkerTrucksProps.crud.fetchList(`${this.customUrl}/common/trucks?vbin=false`, void 0, false);
  },
  methods: {
    storeValue(value, resourceKey) {
      this.writeoffBunkerProps.crud.updateEntity("editForm", {
        resourceKey,
        value
      });
      return;
    },
    onTrucksChange(e) {
      this.tareValue = this.bunkerTrucks.filter((v) => v.id === e)[0].tare;
      this.storeValue(e, "truckId");
    },
    onWeightObsChange(e) {
      if (e !== "") {
        let isNumber = /^\d+$/.test(e);
        if (!isNumber)
          e = this.weightObserved;
        else
          this.weightObserved = parseInt(e);
      } else
        this.weightObserved = e;
      this.storeValue(this.weightObserved, "weight");
    },
    submitModal() {
      this.writeoffBunkerProps.crud.submitEntity("replace", `${this.customUrl}/bunkers/${this.recordItem.id}/write-off/${this.recordItem.bunkerHistId}`).then(() => {
        this.$emit("updated", { type: "writeoff", visible: false });
      });
    },
    cancel() {
      this.$emit("update:visible", false);
    }
  }
};
const __cssModules$9 = {};
var __component__$9 = /* @__PURE__ */ normalizeComponent(__vue2_script$9, render$9, staticRenderFns$9, false, __vue2_injectStyles$9, "261ade36", null, null);
function __vue2_injectStyles$9(context) {
  for (let o in __cssModules$9) {
    this[o] = __cssModules$9[o];
  }
}
var WriteoffBunkerProcessing$1 = /* @__PURE__ */ function() {
  return __component__$9.exports;
}();
var render$8 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "writeoff-bunker-container"
  }, [_c("resource", {
    attrs: {
      "name": ["grower.bunker.writeoff", "grower.bunker.trucks"],
      "api-url": _vm.apiUrl
    }
  }, [_c("writeoff-bunker-processing", {
    attrs: {
      "visible": _vm.visibleModal,
      "record": _vm.recordItem
    },
    on: {
      "update:visible": [function($event) {
        _vm.visibleModal = $event;
      }, _vm.updateVisible],
      "update:record": function($event) {
        _vm.recordItem = $event;
      },
      "updated": function($event) {
        return _vm.updated($event);
      }
    }
  })], 1)], 1);
};
var staticRenderFns$8 = [];
const apiUrl$4 = "#{VUE_APP_API_URL}#";
const __vue2_script$8 = {
  components: {
    WriteoffBunkerProcessing: WriteoffBunkerProcessing$1
  },
  props: {
    record: {
      type: Object,
      default: null
    },
    visible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      apiUrl: apiUrl$4,
      visibleModal: this.visible,
      recordItem: this.record
    };
  },
  watch: {
    visible(newVal) {
      this.visibleModal = newVal;
    },
    record(newVal) {
      this.recordItem = newVal;
    }
  },
  methods: {
    updated(e) {
      this.$emit("updated", e);
    },
    updateVisible(e) {
      this.$emit("update:visible", e);
    }
  }
};
const __cssModules$8 = {};
var __component__$8 = /* @__PURE__ */ normalizeComponent(__vue2_script$8, render$8, staticRenderFns$8, false, __vue2_injectStyles$8, null, null, null);
function __vue2_injectStyles$8(context) {
  for (let o in __cssModules$8) {
    this[o] = __cssModules$8[o];
  }
}
var WriteoffBunkerProcessing = /* @__PURE__ */ function() {
  return __component__$8.exports;
}();
var render$7 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_vm.recordItem && _vm.visibleModal ? _c("a-modal", {
          attrs: {
            "destroy-on-close": "",
            "title": "Open Bunker",
            "visible": _vm.visibleModal,
            "centered": ""
          },
          on: {
            "ok": _vm.submitModal,
            "cancel": _vm.cancel
          },
          scopedSlots: _vm._u([{
            key: "footer",
            fn: function() {
              return [_c("a-button", {
                on: {
                  "click": _vm.cancel
                }
              }, [_vm._v("Cancel")]), _c("a-button", {
                attrs: {
                  "type": "primary"
                },
                on: {
                  "click": function($event) {
                    return handleSubmit(_vm.submitModal);
                  }
                }
              }, [_vm._v("OK")])];
            },
            proxy: true
          }], null, true)
        }, [_c("h4", {
          staticClass: "h4 mb-4"
        }, [_vm._v(_vm._s(_vm.recordItem.description ? _vm.recordItem.description : "BUNKER"))]), _c("p", {
          staticClass: "mb-2 h7 gray"
        }, [_vm._v("Please select Crop and Produce Size to open this bunker:")]), _c("a-row", {
          attrs: {
            "gutter": [24]
          }
        }, [_c("a-col", {
          attrs: {
            "xs": 12
          }
        }, [_c("select-input", {
          attrs: {
            "form-item": "",
            "label": "Crop",
            "data-source": _vm.bunkerCrops,
            "source": "id",
            "source-label": _vm.genCropCodeLabel,
            "rules": "required"
          },
          on: {
            "change": _vm.onCropsChange
          }
        })], 1), _c("a-col", {
          attrs: {
            "xs": 12
          }
        }, [_c("select-input", {
          attrs: {
            "form-item": "",
            "label": "Produce Size",
            "data-source": _vm.bunkerProduceSize,
            "loading": _vm.isLoading,
            "source": "id",
            "source-label": _vm.genProduceLabel,
            "rules": "required"
          },
          on: {
            "change": function($event) {
              return _vm.storeValue($event, "produceSizeId");
            }
          }
        })], 1)], 1)], 1) : _vm._e()];
      }
    }])
  });
};
var staticRenderFns$7 = [];
var OpenBunkerProcessing_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$7 = {
  name: "OpenBunkerProcessing",
  inject: ["apiUrl", "resourceProps"],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    record: {
      type: Object,
      default: null
    }
  },
  data() {
    const [bunkerCropsProps, bunkerProduceSizeProps] = this.resourceProps;
    return {
      bunkerCropsProps,
      bunkerProduceSizeProps,
      customUrl: `${this.apiUrl}/grower`,
      visibleModal: false,
      recordItem: null,
      bunkerProduceSize: null,
      isLoading: false
    };
  },
  computed: {
    bunkerCrops() {
      return this.bunkerCropsProps.crud.getList();
    }
  },
  watch: {
    visible(newVal) {
      this.visibleModal = newVal;
    },
    record(newVal) {
      this.recordItem = newVal;
    }
  },
  async mounted() {
    this.bunkerCropsProps.crud.fetchList(`${this.customUrl}/bunkers/crops-bunker`, void 0, false);
  },
  methods: {
    genCropCodeLabel(option) {
      return `${option.code} - ${option.name}`;
    },
    genProduceLabel(option) {
      return `${option.code} ${option.description}`;
    },
    storeValue(value, resourceKey) {
      this.bunkerCropsProps.crud.updateEntity("createForm", {
        resourceKey,
        value
      });
    },
    onCropsChange(e) {
      this.storeValue(e, "cropId");
      this.isLoading = true;
      this.bunkerProduceSizeProps.crud.fetchList(`${this.customUrl}/bunkers/produce-size/${e}`, void 0, false).then(() => {
        this.bunkerProduceSize = this.bunkerProduceSizeProps.crud.getList(), this.isLoading = false;
      });
    },
    submitModal() {
      this.storeValue(this.recordItem.id, "bunkerId");
      this.bunkerCropsProps.crud.submitEntity("create", `${this.customUrl}/bunkers/open`).then(() => {
        this.$emit("updated", { type: "open", visible: false });
      });
    },
    cancel() {
      this.$emit("update:visible", false);
    }
  }
};
const __cssModules$7 = {};
var __component__$7 = /* @__PURE__ */ normalizeComponent(__vue2_script$7, render$7, staticRenderFns$7, false, __vue2_injectStyles$7, "8805fb1c", null, null);
function __vue2_injectStyles$7(context) {
  for (let o in __cssModules$7) {
    this[o] = __cssModules$7[o];
  }
}
var OpenBunkerProcessing$1 = /* @__PURE__ */ function() {
  return __component__$7.exports;
}();
var render$6 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "open-bunker-container"
  }, [_c("resource", {
    attrs: {
      "name": ["grower.bunker.crops", "grower.bunker.produce-size"],
      "api-url": _vm.apiUrl
    }
  }, [_c("open-bunker-processing", {
    attrs: {
      "visible": _vm.visibleModal,
      "record": _vm.recordItem
    },
    on: {
      "update:visible": [function($event) {
        _vm.visibleModal = $event;
      }, _vm.updateVisible],
      "update:record": function($event) {
        _vm.recordItem = $event;
      },
      "updated": function($event) {
        return _vm.updated($event);
      }
    }
  })], 1)], 1);
};
var staticRenderFns$6 = [];
const apiUrl$3 = "#{VUE_APP_API_URL}#";
const __vue2_script$6 = {
  components: {
    OpenBunkerProcessing: OpenBunkerProcessing$1
  },
  props: {
    record: {
      type: Object,
      default: null
    },
    visible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      apiUrl: apiUrl$3,
      visibleModal: this.visible,
      recordItem: this.record
    };
  },
  watch: {
    visible(newVal) {
      this.visibleModal = newVal;
    },
    record(newVal) {
      this.recordItem = newVal;
    }
  },
  methods: {
    updated(e) {
      this.$emit("updated", e);
    },
    updateVisible(e) {
      this.$emit("update:visible", e);
    }
  }
};
const __cssModules$6 = {};
var __component__$6 = /* @__PURE__ */ normalizeComponent(__vue2_script$6, render$6, staticRenderFns$6, false, __vue2_injectStyles$6, null, null, null);
function __vue2_injectStyles$6(context) {
  for (let o in __cssModules$6) {
    this[o] = __cssModules$6[o];
  }
}
var OpenBunkerProcessing = /* @__PURE__ */ function() {
  return __component__$6.exports;
}();
var render$5 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_vm.recordItem && _vm.visibleModal ? _c("a-modal", {
          attrs: {
            "destroy-on-close": "",
            "title": "Open Bunker",
            "visible": _vm.visibleModal,
            "centered": ""
          },
          on: {
            "ok": _vm.submitModal,
            "cancel": _vm.cancel
          },
          scopedSlots: _vm._u([{
            key: "footer",
            fn: function() {
              return [_c("a-button", {
                on: {
                  "click": _vm.cancel
                }
              }, [_vm._v("Cancel")]), _c("a-button", {
                attrs: {
                  "type": "primary"
                },
                on: {
                  "click": function($event) {
                    return handleSubmit(_vm.submitModal);
                  }
                }
              }, [_vm._v("OK")])];
            },
            proxy: true
          }], null, true)
        }, [_c("h4", {
          staticClass: "h4 mb-4"
        }, [_vm._v(_vm._s(_vm.recordItem.description ? _vm.recordItem.description : "BUNKER"))]), _c("a-row", {
          attrs: {
            "gutter": [24, 24]
          }
        }, [_c("a-col", {
          attrs: {
            "span": 12
          }
        }, [_c("text-input", {
          attrs: {
            "label": "Produce Size",
            "value": _vm.recordItem.produceSize + " " + _vm.recordItem.produceSizeDescription,
            "disabled": true,
            "span": 24
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 6
          }
        }, [_c("text-input", {
          attrs: {
            "label": "Current Weight",
            "value": _vm.recordItem.currentWeight,
            "disabled": true,
            "suffix": "KG",
            "span": 24
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 6
          }
        }, [_c("text-input", {
          attrs: {
            "label": "Weight Cap",
            "value": _vm.recordItem.weightCap,
            "disabled": true,
            "suffix": "KG",
            "span": 24
          }
        })], 1)], 1), _c("h6", {
          staticClass: "h6 mb-2"
        }, [_vm._v("Transfer to")]), _c("a-row", {
          attrs: {
            "gutter": [24]
          }
        }, [_c("a-col", {
          attrs: {
            "xs": 12
          }
        }, [_c("select-input", {
          attrs: {
            "form-item": "",
            "label": "Select Bunker",
            "data-source": _vm.bunkerTransfer,
            "source": "id",
            "source-label": "code",
            "span": 24,
            "rules": "required"
          },
          on: {
            "change": _vm.onBunkersChange
          }
        })], 1)], 1), _c("a-row", {
          attrs: {
            "gutter": [24, 24]
          }
        }, [_c("a-col", {
          attrs: {
            "span": 12
          }
        }, [_c("text-input", {
          attrs: {
            "label": "Produce Size",
            "value": _vm.entity.produceSize,
            "disabled": true,
            "span": 24
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 6
          }
        }, [_c("text-input", {
          attrs: {
            "label": "Current Weight",
            "value": _vm.entity.currentWeight,
            "disabled": true,
            "suffix": "KG",
            "span": 24
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 6
          }
        }, [_c("text-input", {
          attrs: {
            "label": "Weight Cap",
            "value": _vm.entity.weightCap,
            "disabled": true,
            "suffix": "KG",
            "span": 24
          }
        })], 1)], 1)], 1) : _vm._e()];
      }
    }])
  });
};
var staticRenderFns$5 = [];
var TransferBunkerProcessing_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$5 = {
  name: "TransferBunkerProcessing",
  inject: ["apiUrl", "resourceProps"],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    record: {
      type: Object,
      default: null
    }
  },
  data() {
    const [bunkerTransferProps] = this.resourceProps;
    return {
      bunkerTransferProps,
      entity: {
        produceSize: "",
        currentWeight: 0,
        weightCap: 0
      },
      customUrl: `${this.apiUrl}/grower`,
      visibleModal: false,
      recordItem: this.record,
      bunkerProduceSize: null
    };
  },
  computed: {
    bunkerTransfer() {
      return this.bunkerTransferProps.crud.getList();
    }
  },
  watch: {
    visible(newVal) {
      this.entity = { produceSize: "", currentWeight: 0, weightCap: 0 };
      this.visibleModal = newVal;
    },
    record(newVal) {
      this.recordItem = newVal;
      this.fetchList();
    }
  },
  async mounted() {
  },
  methods: {
    fetchList() {
      this.bunkerTransferProps.crud.fetchList(`${this.customUrl}/bunkers/${this.recordItem.id}/bunker-transfer/${this.recordItem.bunkerHistId}`, void 0, false);
    },
    storeValue(value, resourceKey) {
      this.bunkerTransferProps.crud.updateEntity("editForm", {
        resourceKey,
        value
      });
    },
    onBunkersChange(e) {
      this.storeValue(e, "destinationId");
      this.entity = this.bunkerTransfer.filter((item) => item.id === e)[0];
    },
    submitModal() {
      this.storeValue(this.recordItem.bunkerHistId, "sourceId");
      this.bunkerTransferProps.crud.submitEntity("replace", `${this.customUrl}/bunkers/${this.recordItem.id}/bunker-transfer`).then(() => {
        this.$emit("updated", { type: "transfer", visible: false });
      });
    },
    cancel() {
      this.$emit("update:visible", false);
    }
  }
};
const __cssModules$5 = {};
var __component__$5 = /* @__PURE__ */ normalizeComponent(__vue2_script$5, render$5, staticRenderFns$5, false, __vue2_injectStyles$5, "de42b10a", null, null);
function __vue2_injectStyles$5(context) {
  for (let o in __cssModules$5) {
    this[o] = __cssModules$5[o];
  }
}
var TransferBunkerProcessing$1 = /* @__PURE__ */ function() {
  return __component__$5.exports;
}();
var render$4 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "open-bunker-container"
  }, [_c("resource", {
    attrs: {
      "name": ["grower.bunker.transfer"],
      "api-url": _vm.apiUrl
    }
  }, [_c("transfer-bunker-processing", {
    attrs: {
      "visible": _vm.visibleModal,
      "record": _vm.recordItem
    },
    on: {
      "update:visible": [function($event) {
        _vm.visibleModal = $event;
      }, _vm.updateVisible],
      "update:record": function($event) {
        _vm.recordItem = $event;
      },
      "updated": function($event) {
        return _vm.updated($event);
      }
    }
  })], 1)], 1);
};
var staticRenderFns$4 = [];
const apiUrl$2 = "#{VUE_APP_API_URL}#";
const __vue2_script$4 = {
  components: {
    TransferBunkerProcessing: TransferBunkerProcessing$1
  },
  props: {
    record: {
      type: Object,
      default: null
    },
    visible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      apiUrl: apiUrl$2,
      visibleModal: this.visible,
      recordItem: this.record
    };
  },
  watch: {
    visible(newVal) {
      this.visibleModal = newVal;
    },
    record(newVal) {
      this.recordItem = newVal;
    }
  },
  methods: {
    updated(e) {
      this.$emit("updated", e);
    },
    updateVisible(e) {
      this.$emit("update:visible", e);
    }
  }
};
const __cssModules$4 = {};
var __component__$4 = /* @__PURE__ */ normalizeComponent(__vue2_script$4, render$4, staticRenderFns$4, false, __vue2_injectStyles$4, null, null, null);
function __vue2_injectStyles$4(context) {
  for (let o in __cssModules$4) {
    this[o] = __cssModules$4[o];
  }
}
var TransferBunkerProcessing = /* @__PURE__ */ function() {
  return __component__$4.exports;
}();
var render$3 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _vm.recordItem && _vm.visibleModal ? _c("a-modal", {
    attrs: {
      "destroy-on-close": "",
      "title": "View Bunker History",
      "visible": _vm.visibleModal,
      "width": 832,
      "bodyStyle": {
        maxHeight: "600px",
        overflow: "auto"
      },
      "centered": ""
    },
    on: {
      "cancel": _vm.cancel
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function() {
        return [_c("a-button", {
          attrs: {
            "type": "primary"
          },
          on: {
            "click": _vm.cancel
          }
        }, [_vm._v("Done")])];
      },
      proxy: true
    }], null, false, 1971174158)
  }, [_c("h4", {
    staticClass: "h4 mb-4"
  }, [_vm._v(" " + _vm._s(_vm.recordItem.description ? _vm.recordItem.description : "BUNKER") + " ")]), _vm.recordItem ? _c("list-composer", {
    attrs: {
      "has-active": false,
      "has-filter-inactive": false,
      "has-action": false,
      "has-advanced-search": false,
      "has-filter": false,
      "custom-url": _vm.getListUrl,
      "search-by": "CropCode, ProduceSize",
      "ph-search-by": "Search by Crop Code, Produce Size",
      "custom-padding-table": "p-0 bg-white"
    }
  }, [_c("text-field", {
    attrs: {
      "data-index": "id",
      "title": "History ID",
      "sorter": true
    }
  }), _c("text-field", {
    attrs: {
      "data-index": "produceSize",
      "title": "Produce Size"
    }
  }), _c("text-field", {
    attrs: {
      "data-index": "cropCode",
      "title": "Crop Code"
    }
  }), _c("text-field", {
    attrs: {
      "data-index": "currentWeight",
      "title": "Current Weight"
    }
  }), _c("text-field", {
    attrs: {
      "data-index": "weightCap",
      "title": "Weight Cap"
    }
  })], 1) : _vm._e()], 1) : _vm._e();
};
var staticRenderFns$3 = [];
const __vue2_script$3 = {
  name: "HistoryBunkerProcessing",
  inject: ["apiUrl", "crud"],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    record: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      customUrl: `${this.apiUrl}/grower`,
      visibleModal: false,
      recordItem: this.record,
      getListUrl: ""
    };
  },
  watch: {
    visible(newVal) {
      this.crud.deleteFilter("CropCode, ProduceSize");
      this.visibleModal = newVal;
    },
    record(newVal) {
      this.recordItem = newVal;
      if (this.visible) {
        this.getListUrl = "";
        this.getListUrl = `${this.customUrl}/bunkers/${this.recordItem.id}/bunker-history`;
        this.fetchList(this.getListUrl);
      }
    }
  },
  methods: {
    fetchList(customUrl) {
      this.crud.clearFilters();
      const { count, pageSize } = this.crud.getPagination();
      this.crud.setPagination({ count, page: 1, pageSize });
      this.crud.deleteFilter("IsInactive");
      this.crud.fetchList(customUrl, void 0, false);
    },
    cancel() {
      this.$emit("update:visible", false);
    }
  }
};
const __cssModules$3 = {};
var __component__$3 = /* @__PURE__ */ normalizeComponent(__vue2_script$3, render$3, staticRenderFns$3, false, __vue2_injectStyles$3, null, null, null);
function __vue2_injectStyles$3(context) {
  for (let o in __cssModules$3) {
    this[o] = __cssModules$3[o];
  }
}
var HistoryBunkerProcessing$1 = /* @__PURE__ */ function() {
  return __component__$3.exports;
}();
var render$2 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "open-bunker-container"
  }, [_c("resource", {
    attrs: {
      "name": "grower.bunkers.bunker-history?",
      "api-url": _vm.apiUrl
    }
  }, [_c("history-bunker-processing", {
    attrs: {
      "visible": _vm.visibleModal,
      "record": _vm.recordItem
    },
    on: {
      "update:visible": [function($event) {
        _vm.visibleModal = $event;
      }, _vm.updateVisible],
      "update:record": function($event) {
        _vm.recordItem = $event;
      }
    }
  })], 1)], 1);
};
var staticRenderFns$2 = [];
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$2 = {
  components: {
    HistoryBunkerProcessing: HistoryBunkerProcessing$1
  },
  props: {
    record: {
      type: Object,
      default: null
    },
    visible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      apiUrl: apiUrl$1,
      visibleModal: this.visible,
      recordItem: this.record
    };
  },
  watch: {
    visible(newVal) {
      this.visibleModal = newVal;
    },
    record(newVal) {
      this.recordItem = newVal;
    }
  },
  methods: {
    updateVisible(e) {
      this.$emit("update:visible", e);
    }
  }
};
const __cssModules$2 = {};
var __component__$2 = /* @__PURE__ */ normalizeComponent(__vue2_script$2, render$2, staticRenderFns$2, false, __vue2_injectStyles$2, null, null, null);
function __vue2_injectStyles$2(context) {
  for (let o in __cssModules$2) {
    this[o] = __cssModules$2[o];
  }
}
var HistoryBunkerProcessing = /* @__PURE__ */ function() {
  return __component__$2.exports;
}();
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "bunker-processing"
  }, [_vm.tableRendered ? _c("list-composer", {
    attrs: {
      "title": "Bunker Processing",
      "has-active": false,
      "actions-width": 140,
      "disabled-row": _vm.disabledRow,
      "search-by": "Description,CropCode",
      "ph-search-by": "Search by Bunker, Crop Code",
      "custom-col": _vm.customCol,
      "has-filter-inactive": false
    },
    scopedSlots: _vm._u([{
      key: "customFilter",
      fn: function() {
        return [_c("div", {
          staticClass: "status-filter mr-2"
        }, [_c("select-filter", {
          attrs: {
            "source": "value",
            "source-label": "text",
            "data-source": _vm.filterOptions,
            "value": _vm.selectedStatus
          },
          on: {
            "change": _vm.filterStatus
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "customCol",
      fn: function(_ref) {
        var record = _ref.record;
        return [_c("a-switch", {
          attrs: {
            "disabled": !_vm.hasOpenPermission
          },
          on: {
            "change": function($event) {
              return _vm.onOpenChange(record);
            }
          },
          model: {
            value: record.isOpened,
            callback: function($$v) {
              _vm.$set(record, "isOpened", $$v);
            },
            expression: "record.isOpened"
          }
        })];
      }
    }, {
      key: "action",
      fn: function(_ref2) {
        var record = _ref2.record;
        return [_vm.hasTransferPermission ? _c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v("Transfer")];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          staticClass: "btn-bunker",
          attrs: {
            "icon": "retweet",
            "size": "small",
            "type": "link",
            "disabled": record.status === "CLOSED"
          },
          on: {
            "click": function($event) {
              return _vm.openModal("transfer", record);
            }
          }
        })], 1) : _vm._e(), _vm.hasHoldPermission ? _c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v(_vm._s(record.status === "HOLD" ? "Un - Hold" : "Quality Hold"))];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          class: ["btn-bunker", {
            "btn-unhold": record.status !== "HOLD"
          }],
          attrs: {
            "icon": record.status === "HOLD" ? "play-circle" : "pause-circle",
            "size": "small",
            "type": "link",
            "disabled": record.status === "CLOSED"
          },
          on: {
            "click": function($event) {
              return _vm.changeBunkerStatus(record.status === "HOLD" ? "Unhold" : "Hold", record);
            }
          }
        })], 1) : _vm._e(), _vm.hasWriteoffPermission ? _c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v("Writeoff")];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          staticClass: "btn-bunker",
          attrs: {
            "icon": "file-excel",
            "size": "small",
            "type": "link",
            "disabled": record.status === "CLOSED"
          },
          on: {
            "click": function($event) {
              return _vm.openModal("writeoff", record);
            }
          }
        })], 1) : _vm._e(), _vm.hasHistoryPermission ? _c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v("View History")];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          staticClass: "btn-bunker",
          attrs: {
            "icon": "read",
            "size": "small",
            "type": "link",
            "disabled": record.status === "CLOSED"
          },
          on: {
            "click": function($event) {
              return _vm.openModal("history", record);
            }
          }
        })], 1) : _vm._e()];
      }
    }], null, false, 3017491467)
  }, [_c("text-field", {
    key: "BunkerHistId",
    attrs: {
      "data-index": "bunkerHistId",
      "title": "History ID"
    }
  }), _c("text-field", {
    key: "Description",
    attrs: {
      "data-index": "description",
      "title": "Bunker",
      "sorter": true
    }
  }), _c("text-field", {
    key: "ProduceSize",
    attrs: {
      "data-index": "produceSize",
      "title": "Produce Size"
    }
  }), _c("text-field", {
    key: "CropCode",
    attrs: {
      "data-index": "cropCode",
      "title": "Crop Code",
      "sorter": true
    }
  }), _c("text-field", {
    key: "CurrentWeight",
    attrs: {
      "data-index": "currentWeight",
      "title": "Current Weight"
    }
  }), _c("text-field", {
    key: "WeightCap",
    attrs: {
      "data-index": "weightCap",
      "title": "Weight Cap"
    }
  }), _c("tag-field", {
    key: "Status",
    attrs: {
      "data-index": "status",
      "title": "Status",
      "color": _vm.getStatusColor,
      "parse": _vm.parseStatus
    }
  })], 1) : _vm._e(), _vm.hasWriteoffPermission ? _c("writeoff-bunker-processing", {
    attrs: {
      "visible": _vm.visibleWriteoff,
      "record": _vm.record
    },
    on: {
      "update:visible": function($event) {
        _vm.visibleWriteoff = $event;
      },
      "update:record": function($event) {
        _vm.record = $event;
      },
      "updated": _vm.onUpdated
    }
  }) : _vm._e(), _vm.hasOpenPermission ? _c("open-bunker-processing", {
    attrs: {
      "visible": _vm.visibleOpen,
      "record": _vm.record
    },
    on: {
      "update:visible": function($event) {
        _vm.visibleOpen = $event;
      },
      "update:record": function($event) {
        _vm.record = $event;
      },
      "updated": _vm.onUpdated
    }
  }) : _vm._e(), _vm.hasTransferPermission ? _c("transfer-bunker-processing", {
    attrs: {
      "visible": _vm.visibleTransfer,
      "record": _vm.record
    },
    on: {
      "update:visible": function($event) {
        _vm.visibleTransfer = $event;
      },
      "update:record": function($event) {
        _vm.record = $event;
      },
      "updated": _vm.onUpdated
    }
  }) : _vm._e(), _vm.hasHistoryPermission ? _c("history-bunker-processing", {
    attrs: {
      "visible": _vm.visibleHistory,
      "record": _vm.record
    },
    on: {
      "update:visible": function($event) {
        _vm.visibleHistory = $event;
      },
      "update:record": function($event) {
        _vm.record = $event;
      },
      "updated": _vm.onUpdated
    }
  }) : _vm._e()], 1);
};
var staticRenderFns$1 = [];
var ListBunkerProcessing_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$1 = {
  name: "ListBunkerProcessing",
  inject: ["apiUrl", "crud", "can"],
  components: {
    WriteoffBunkerProcessing,
    OpenBunkerProcessing,
    TransferBunkerProcessing,
    HistoryBunkerProcessing
  },
  data() {
    return {
      field: "BunkerStatus",
      filterOptions: [
        {
          value: "all",
          text: "All Status",
          color: "black"
        },
        {
          value: "OPEN"
        },
        {
          value: "HOLD"
        },
        {
          value: "CLOSED"
        },
        {
          value: "LOCKED"
        }
      ],
      visibleOpen: false,
      visibleWriteoff: false,
      visibleHistory: false,
      visibleTransfer: false,
      record: null,
      tableRendered: true,
      disabledRow: {
        field: "status",
        condition: "CLOSED"
      },
      customCol: {
        title: "Open",
        width: "120px",
        align: "center"
      }
    };
  },
  computed: {
    hasOpenPermission() {
      return this.can(OPEN_PERMISSION);
    },
    hasTransferPermission() {
      return this.can(TRANSFER_PERMISSION);
    },
    hasHoldPermission() {
      return this.can(HOLD_PERMISSION);
    },
    hasWriteoffPermission() {
      return this.can(WRITEOFF_PERMISSION);
    },
    hasHistoryPermission() {
      return this.can(HISTORY_PERMISSION);
    },
    selectedStatus() {
      const statusFilter = this.crud.getFilters().get(this.field);
      return statusFilter || { value: "all" };
    }
  },
  created() {
    this.filterOptions.filter((key) => key.value !== "all").map((key) => {
      Object.assign(key, {
        text: BUNKER_STATUS_TEXTS[key.value],
        color: BUNKER_STATUS_COLORS[key.value]
      });
    });
    this.crud.clearFilters();
    const { count, pageSize } = this.crud.getPagination();
    this.crud.setPagination({ count, page: 1, pageSize });
    this.crud.deleteFilter("IsInactive");
    this.fetchList();
  },
  methods: {
    parseOpenValue(val) {
      return val.status !== "CLOSED" ? true : false;
    },
    parseOpenValueOnChange(val, record) {
      if (!val)
        this.changeBunkerStatus("Closed", record);
      else
        this.changeVisibleModal("open");
      return val ? -1 : 0;
    },
    getStatusColor(value) {
      return BUNKER_STATUS_COLORS[value];
    },
    parseStatus(value) {
      return BUNKER_STATUS_TEXTS[value];
    },
    storeValue(value, resourceKey) {
      if (!resourceKey.includes(",")) {
        this.crud.updateEntity("editForm", {
          resourceKey,
          value
        });
        return;
      }
      const resourceKeys = resourceKey.split(",");
      resourceKeys.forEach((key) => this.crud.updateEntity("editForm", {
        resourceKey: key,
        value: value[key]
      }));
    },
    filterStatus(status) {
      if (status.value == "all")
        this.crud.deleteFilter(this.field);
      else {
        const { count, pageSize } = this.crud.getPagination();
        this.crud.setPagination({ count, page: 1, pageSize });
        this.crud.setFilter(this.field, status);
      }
      this.fetchList();
    },
    changeVisibleModal(type) {
      switch (type) {
        case "writeoff":
          this.visibleWriteoff = !this.visibleWriteoff;
          break;
        case "transfer":
          this.visibleTransfer = !this.visibleTransfer;
          break;
        case "history":
          this.visibleHistory = !this.visibleHistory;
          break;
        case "open":
          this.visibleOpen = !this.visibleOpen;
          break;
      }
    },
    openModal(type, record) {
      this.record = record;
      this.changeVisibleModal(type);
    },
    changeBunkerStatus(status, record) {
      this.storeValue(status, "status");
      this.crud.submitEntity("replace", `${this.apiUrl}/grower/bunkers/${record.id}/status/${record.bunkerHistId}`).finally(() => this.fetchList());
    },
    fetchList() {
      return this.crud.fetchList();
    },
    onOpenChange(record) {
      if (record.isOpened) {
        record.isOpened = false;
        this.openModal("open", record);
      } else
        this.changeBunkerStatus("Closed", record);
    },
    onUpdated(e) {
      this.tableRendered = false;
      this.fetchList().then(() => {
        this.tableRendered = true;
        this.changeVisibleModal(e.type);
      });
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "fea87f22", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListBunkerProcessing = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "list-bunker"
  }, [_c("bread-crumb", {
    attrs: {
      "items": _vm.itemsMenu
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.bunkers",
      "api-url": _vm.apiUrl,
      "filter-route": "/watties-grower/bunker-processing/advanced-search",
      "page": _vm.page
    }
  }, [_c("list-bunker-processing")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ListBunkerProcessing
  },
  data() {
    return {
      apiUrl,
      page: GROWERS_BUNKERPROCESSING,
      itemsMenu: [
        {
          key: "bunkers",
          title: "Bunker Processing",
          path: ""
        },
        {
          key: "bunkerprocessing",
          title: "Bunker Processing",
          path: "/watties-grower/bunker-processing"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
