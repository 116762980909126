const BUNKER_STATUS_OPEN = "OPEN";
const BUNKER_STATUS_CLOSE = null;
const BUNKER_STATUS_CLOSED = "CLOSED";
const BUNKER_STATUS_LOCKED = "LOCKED";
const BUNKER_STATUS_HOLD = "HOLD";
const BUNKER_STATUS_ACTIVE = "ACTIVE";
const BUNKER_STATUS_COLORS = {
  [BUNKER_STATUS_OPEN]: "green",
  [BUNKER_STATUS_CLOSE]: "red",
  [BUNKER_STATUS_CLOSED]: "red",
  [BUNKER_STATUS_LOCKED]: "gray",
  [BUNKER_STATUS_HOLD]: "orange"
};
const BUNKER_STATUS_TEXTS = {
  [BUNKER_STATUS_OPEN]: "OPEN",
  [BUNKER_STATUS_HOLD]: "HOLD",
  [BUNKER_STATUS_CLOSED]: "CLOSED",
  [BUNKER_STATUS_LOCKED]: "LOCKED",
  [BUNKER_STATUS_ACTIVE]: "ACTIVE"
};
export { BUNKER_STATUS_TEXTS as B, BUNKER_STATUS_COLORS as a };
